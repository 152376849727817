<template>
	<div>
		<div class="mp3-bg pb-5">
			<div class="container pt-4 pb-4">
				<div class="row">
					<div class="col-12 pt-5">
						<h2 v-html="breakText($t('message.services.title'))"></h2>
					</div>
				</div>
			</div>
		</div>

		<div class="container pt-5 services">
			<div class="row">
				<div class="col-12 col-md-8">
					<div class="row">
						<div class="col-12 pb-3">
							<h1>{{ $t('message.services.title2') }}</h1>
						</div>
						<div class="col-12 pb-3">
							<p>{{ $t('message.services.text1') }}</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="row">
						<div class="col-12 pb-2">
							<img :src="require(`@/assets/images/Lubrirent_profissionais_01.jpg`)" class="img-fluid" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="pb-5">
			<form @submit.prevent="submit">
				<div class="container contacts pb-4">
					<div class="row">
						<div class="col-12 pb-3">
							<h3>{{ $t('message.services.text2') }}</h3>
						</div>
						<div class="col-12">
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="name" :class="{ 'hasError': $v.form.name.$error }">{{
												$t('message.contacts.label2')
											}}<span
												style="color:red;"> *</span></label>
										<input type="text" v-model="form.name" class="form-control" id="name"
											   name="name">
									</div>
									<div class="form-group">
										<label for="company_name">{{ $t('message.contacts.label4') }}</label>
										<input type="text" v-model="form.company_name" class="form-control"
											   id="company_name"
											   name="company_name">
									</div>
									<div class="form-group">
										<label for="email" :class="{ 'hasError': $v.form.email.$error }">{{
												$t('message.contacts.label3')
											}}<span
												style="color:red;"> *</span></label>
										<input type="email" v-model="form.email" id="email" class="form-control"
											   name="email">
									</div>
									<div class="form-group">
										<label for="phone" :class="{ 'hasError': $v.form.phone.$error }">{{
												$t('message.contacts.label5')
											}}<span
												style="color:red;"> *</span></label>
										<input type="text" v-model="form.phone" id="phone" class="form-control"
											   name="phone">
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="message" :class="{ 'hasError': $v.form.message.$error }">{{
												$t('message.contacts.label1')
											}}<span
												style="color:red;"> *</span></label>
										<textarea v-model="form.message" class="form-control" name="message"
												  id="message"
												  rows="10"></textarea>
									</div>

									<p><span style="color:red;"> *</span> {{ $t('message.contacts.required') }}</p>

									<button class="btn btn-primary">
										{{ $t('message.services.button') }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="mp-dark-bg">
			<div class="container">
				<WhyZone></WhyZone>
			</div>
		</div>

		<div class="mp-yellow-bg">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
							<div class="d-md-flex justify-content-md-start content">
								{{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{
									$t('message.services.contactus')
								}}!</span>
							</div>

							<div class="d-md-flex justify-content-md-end content">
								<a href="tel:+351 924 114 635"><i class="fas fa-phone mr-2"></i>+351 924 114 635</a>
								<router-link class="btn btn-primary mt-2 mt-md-0"
											 :to="{name:'contacts', params : {lang:lang}}">
									<i
										class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
								</router-link>
							</div>
						</div>
						<p class="text-right" style="font-size:0.75rem;font-weight:400;margin: 15px;">{{ $t('message.cost_mobile') }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>

<script>

import axios             from 'axios';
import {email, required} from 'vuelidate/lib/validators';
import json              from '../../website-data.json';
import Location          from '../components/Location';
import WhyZone           from '../components/WhyZone';

export default {
	name       : 'ServicesPage',
	components : {
		WhyZone,
		Location
	},
	data       : function () {
		return {
			json : json,
			lang : this.$route.params.lang,
			form : {
				name         : '',
				email        : '',
				message      : '',
				company_name : '',
				phone        : ''
			}
		};
	},
	computed   : {
		rules() {
			return {
				name    : { required },
				email   : { required, email },
				message : { required },
				phone   : { required }
			};
		},
	},
	methods    : {
		breakText(text) {
			if (window.innerWidth < 768) {
				let cenas = text.split("&");
				return cenas[0]+ '& <br>' + cenas[1]
			}
			return text;
		},
		submit(e) {
			e.preventDefault();
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				return;
			}
			let vm = this;

			axios.post(`${process.env.VUE_APP_RESERVATION_URL}/contact-pro`, vm.form, {
					headers : {
						'Content-Type'                : 'application/json',
						'Access-Control-Allow-Origin' : '*',
						'Accept'                      : 'application/json',
					}
				})
				.then(function (response) {
					let text = vm.lang === 'pt' ? 'Pedido de contacto enviado com sucesso!' : 'Message sent successfully!';
					vm.$swal.fire({
						text              : text,
						type              : 'success',
						icon              : 'success',
						confirmButtonText : '',
						showConfirmButton : false,
						allowOutsideClick : false,
						timer             : 5000
					});

					if (vm.$gtm.enabled()) {
						window.dataLayer?.push({
							event          : 'form_submit',
							category       : 'lead_form',
							action         : 'empresas',
							label          : '',
							value          : 0,
							noninteraction : false // Optional
						});
					}


					e.target.reset();
				})
				.catch(function (error) {
					vm.errors      = ['Error. Something happened.'];
					vm.loadSpinner = false;
					vm.disabled    = false;
				});
		}
	},
	validations() {
		return {
			form : this.rules
		};
	},
};
</script>
